import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import InputAdornment from '@mui/material/InputAdornment'
import { DateRangePicker } from 'rsuite'
import MDInput from 'components/MDInput'
import Icon from '@mui/material/Icon'
import { RotatingLines } from 'react-loader-spinner'
function Coupon() {
  const navigate = useNavigate()
  const token = localStorage.getItem('userToken')
  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  let searchValue = ''
  const handleSearchChange = (event) => {
    searchValue = event.target.value
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    fetchData(currentPage, searchValue, dateRange)
  }

  const handleAddButtonClick = () => {
    navigate('/coupon/add')
  }

  const handleDownloadFormatButtonClick = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/downloadExcelSheetFormat`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        throw new Error('Failed to download the file')
      }

      const blob = await response.blob()

      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Coupons.xlsx')

      document.body.appendChild(link)

      link.click()

      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url)
      toast.success('Excel sheet downloaded successfully')
    } catch (error) {
      toast.error('Error downloading the file:', error)
    }
  }

  const handleImportDataButtonClick = async (event) => {
    const file = event.target.files[0]
    if (!file) {
      toast.error('Please select a file to upload')
      return
    }
    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/importExcelSheetData`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })

      if (!response.ok) {
        throw new Error('Failed to upload the file')
      }

      toast.success('Data imported successfully')
      fetchData(currentPage, searchValue, dateRange)
    } catch (error) {
      toast.error(`Error: ${error.message}`)
    }
  }

  const [data, setData] = useState({
    columns: [
      {
        Header: 'Sr.No',
        accessor: 'srno',
        align: 'left',
      },
      {
        Header: 'Wallet Address',
        accessor: 'address',
        align: 'left',
      },

      {
        Header: 'Created At',
        accessor: 'created_at',
        align: 'left',
      },
    ],
    rows: [],
  })

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  const fetchData = useCallback(
    async (currentPage, searchValue, range = null) => {
      setLoading(true)
      try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}users/get-user-coupons/${currentPage}`

        const dateQuery = range ? `?startDate=${range[0]?.toISOString()}&endDate=${range[1]?.toISOString()}` : ''
        const searchQuery = searchValue ? `${dateQuery ? '&' : '?'}search=${searchValue}` : ''
        const url = `${baseUrl}${dateQuery}${searchQuery}`

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })

        if (!response.ok) {
          toast.error('The coupon list is not found.')
        }

        const jsonData = await response.json()
        setLoading(false)
        const rows = jsonData.data.userData.map((user, index) => ({
          srno: (currentPage - 1) * 10 + index + 1,
          address: user.address ? user.address : '-',
          created_at: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {formatDate(user.created_at)}
              </MDTypography>
            </MDBox>
          ),
        }))

        const totalRows = jsonData.data.totalUsersCount

        setData((prevData) => ({
          ...prevData,
          rows: rows,
          totalRows: totalRows,
        }))
      } catch (error) {
        console.log('Error fetching data:', error.message)
      }
    },
    [token],
  )

  useEffect(() => {
    fetchData(currentPage, searchValue, dateRange)
  }, [currentPage, fetchData, searchValue, dateRange])

  const [rowsPerPage] = useState(10)
  const totalPagesRowCount = data?.totalRows || 0
  const totalPages = Math.ceil(totalPagesRowCount / rowsPerPage)
  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange)
    setCurrentPage(1)
    fetchData(1, searchValue, newRange)
  }
  const handleCurrentPageChange = (event, value) => {
    setCurrentPage(value)
    fetchData(value)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                sx={{
                  gap: 2,
                }}
              >
                <MDTypography variant="h6" color="white" sx={{ flex: '1 1 auto' }}>
                  Coupons (100%)
                </MDTypography>
                <MDBox
                  display="flex"
                  flexWrap="wrap"
                  gap={1}
                  justifyContent="flex-end"
                  sx={{
                    flex: '1 1 auto',
                  }}
                >
                  <MDButton variant="gradient" color="dark" size="large" onClick={handleAddButtonClick}>
                    <Icon fontSize="small">add</Icon>&nbsp; Add Coupons
                  </MDButton>
                  <MDButton variant="gradient" color="dark" size="large" onClick={handleDownloadFormatButtonClick}>
                    <Icon fontSize="small">download</Icon> &nbsp;Download Format
                  </MDButton>
                  {/* <MDButton variant="gradient" color="dark" size="large" onClick={handleImportDataButtonClick}>
                    <input type="file" accept=".xlsx" onChange={handleImportDataButtonClick} />
                    <Icon fontSize="small">import_export</Icon> &nbsp;Import Data
                  </MDButton> */}
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="large"
                      onClick={() => document.getElementById('fileInput').click()}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Icon fontSize="small">import_export</Icon> &nbsp;Import Data
                    </MDButton>
                    <input
                      id="fileInput"
                      type="file"
                      accept=".xlsx"
                      onChange={handleImportDataButtonClick}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </MDBox>
              </MDBox>

              <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <MDBox display="flex" alignItems="center" mt={3} px={2}>
                    <DateRangePicker
                      onChange={(range) => {
                        handleDateRangeChange(range)
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox display="flex" alignItems="center" mt={3} px={2}>
                    <form onSubmit={handleSearchSubmit}>
                      <MDInput
                        type="text"
                        placeholder="Search by address"
                        fullWidth
                        onChange={handleSearchChange}
                        InputProps={{
                          sx: {
                            paddingRight: 0,
                            borderRadius: '9.434rem !important',
                          },
                          endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: 0 }}>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                sx={{
                                  minWidth: 'auto',
                                  padding: '6px 12px',
                                  borderRadius: '50%',
                                }}
                              >
                                <Icon fontSize="small">search</Icon>
                              </MDButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox pt={3}>
                {loading ? (
                  <MDTypography align="center">
                    <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                  </MDTypography>
                ) : (
                  <DataTable table={data} isSorted={false} entriesPerPage={false} showTotalEntries={false} canSearch={true} noEndBorder />
                )}

                {/* <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    startIcon={<Icon>chevron_left</Icon>}
                    onClick={handlePrePage}
                    disabled={page === 1}
                    sx={{ minWidth: 120 }}
                  >
                    Previous
                  </MDButton>

                  <MDTypography variant="h6" color="text">
                    Page {page} of {totalPages}
                  </MDTypography>

                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    endIcon={<Icon>chevron_right</Icon>}
                    onClick={handleNextPage}
                    disabled={totalPages === 0 || page === totalPages}
                    sx={{ minWidth: 120 }}
                  >
                    Next
                  </MDButton>
                </MDBox> */}
                <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                  <Stack spacing={2}>
                    <Pagination count={totalPages} color="primary" variant="outlined" shape="rounded" page={currentPage} onChange={handleCurrentPageChange} />
                  </Stack>{' '}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default Coupon
