import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import { useNavigate } from 'react-router-dom'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import toast, { Toaster } from 'react-hot-toast'
import { RotatingLines } from 'react-loader-spinner'
import InputAdornment from '@mui/material/InputAdornment'
import { DateRangePicker } from 'rsuite'
import MDInput from 'components/MDInput'
import Icon from '@mui/material/Icon'
function Notification() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem('userToken')
  const [dateRange, setDateRange] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  let searchValue = ''
  const handleSearchChange = (event) => {
    searchValue = event.target.value
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    fetchData(currentPage, searchValue, dateRange)
  }

  const handleAddButtonClick = () => {
    navigate('/notification/add')
  }
  const [data, setData] = useState({
    columns: [
      {
        Header: 'Sr.No',
        accessor: 'srno',
        align: 'left',
      },
      {
        Header: 'Message',
        accessor: 'message',
        align: 'left',
      },
      // {
      //   Header: 'Total User',
      //   accessor: 'user_count',
      //   align: 'left',
      // },
      {
        Header: 'User Wallet Address',
        accessor: 'users',
        align: 'left',
      },
      {
        Header: 'Notification Tag',
        accessor: 'notification_tag',
        align: 'left',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        align: 'left',
      },
    ],
    rows: [],
  })

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  const fetchData = useCallback(
    async (currentPage, searchValue, range = null) => {
      setLoading(true)
      try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}users/get-user-notifications/${currentPage}`

        const dateQuery = range ? `?startDate=${range[0]?.toISOString()}&endDate=${range[1]?.toISOString()}` : ''
        const searchQuery = searchValue ? `${dateQuery ? '&' : '?'}search=${searchValue}` : ''
        const url = `${baseUrl}${dateQuery}${searchQuery}`

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        // console.log(response)
        if (!response.ok) {
          toast.error('The user information is not found.')
        }

        const jsonData = await response.json()
        setLoading(false)

        const rows = jsonData.data.userData.map((user, index) => ({
          srno: (currentPage - 1) * 10 + index + 1,
          notification_tag: user.notification_tag,
          message: user.message,
          users: user.address,
          // user_count: user.user_ids.length,
          // users: (
          //   <MDBox
          //     sx={{
          //       maxHeight: 150, // Adjust max height as needed
          //       overflowY: 'auto', // Allows scrolling if the content exceeds max height
          //     }}
          //   >
          //     {user.userDetails.map((item) => (
          //       <List key={item._id}>
          //         {' '}
          //         {/* Ensure _id is unique */}
          //         <ListItem style={{ color: 'rgb(5 110 17)' }}>
          //           <ListItemIcon style={{ color: ' rgb(5 110 17)', width: 1 }}>
          //             <Icon fontSize="medium">person</Icon>
          //           </ListItemIcon>
          //           {/* Ensure the field you're trying to display exists */}
          //           <ListItemText primary={item.address || item.name || 'Unknown'} /> {/* Replace 'address' if needed */}
          //         </ListItem>
          //       </List>
          //     ))}
          //   </MDBox>
          // ),
          created_at: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {formatDate(user.created_at)}
              </MDTypography>
            </MDBox>
          ),
        }))

        const totalRows = jsonData.data.totalUsersCount

        setData((prevData) => ({
          ...prevData,
          rows: rows,
          totalRows: totalRows,
        }))
      } catch (error) {
        console.log('Error fetching data:', error.message)
      }
    },
    [token],
  )

  useEffect(() => {
    fetchData(currentPage, searchValue, dateRange)
  }, [currentPage, fetchData, searchValue, dateRange])

  const [rowsPerPage] = useState(10)
  const totalPagesRowCount = data?.totalRows || 0
  const totalPages = Math.ceil(totalPagesRowCount / rowsPerPage)

  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange)
    setCurrentPage(1)
    fetchData(1, searchValue, newRange)
  }

  const handleCurrentPageChange = (event, value) => {
    setCurrentPage(value)
    fetchData(value)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Notification List
                </MDTypography>
                <MDButton variant="gradient" color="dark" size="large" onClick={handleAddButtonClick}>
                  Send Notification
                </MDButton>
              </MDBox>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <MDBox display="flex" alignItems="center" mt={3} px={2}>
                    <DateRangePicker
                      onChange={(range) => {
                        handleDateRangeChange(range)
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox display="flex" alignItems="center" mt={3} px={2}>
                    <form onSubmit={handleSearchSubmit}>
                      <MDInput
                        type="text"
                        placeholder="Search by address"
                        fullWidth
                        onChange={handleSearchChange}
                        InputProps={{
                          sx: {
                            paddingRight: 0,
                            borderRadius: '9.434rem !important',
                          },
                          endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: 0 }}>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                sx={{
                                  minWidth: 'auto',
                                  padding: '6px 12px',
                                  borderRadius: '50%',
                                }}
                              >
                                <Icon fontSize="small">search</Icon>
                              </MDButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox pt={3}>
                {loading ? (
                  <MDTypography align="center">
                    <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                  </MDTypography>
                ) : (
                  <DataTable table={data} isSorted={false} entriesPerPage={false} showTotalEntries={false} canSearch={true} noEndBorder />
                )}

                {/* <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                  
                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    startIcon={<Icon>chevron_left</Icon>}
                    onClick={handlePrePage}
                    disabled={page === 1}  
                    sx={{ minWidth: 120 }}
                  >
                    Previous
                  </MDButton>

                
                  <MDTypography variant="h6" color="text">
                    Page {page} of {totalPages}
                  </MDTypography>

                 
                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    endIcon={<Icon>chevron_right</Icon>}
                    onClick={handleNextPage}
                    disabled={totalPages === 0 || page === totalPages}  
                    sx={{ minWidth: 120 }}
                  >
                    Next
                  </MDButton>
                </MDBox> */}
                <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                  <Stack spacing={2}>
                    <Pagination count={totalPages} color="primary" variant="outlined" shape="rounded" page={currentPage} onChange={handleCurrentPageChange} />
                  </Stack>{' '}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default Notification
