import axios from 'axios'
import { authRequest, doneSuccess, getError } from './user-slice'

export const getUsersTotalCount = (token) => async (dispatch) => {
  dispatch(authRequest())

  try {
    const result = await axios.get(`${process.env.REACT_APP_API_BASE_URL}users/getUsersTotalCount`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    if (result.data) {
      dispatch(doneSuccess(result.data))
    } else {
      dispatch(getError(result))
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message || error.response.statusText : error.message || 'An unexpected error occurred.'
    const errorCode = error.response ? error.response.data.statusCode || error.response.statusText : error.statusCode || 'An unexpected error occurred.'
    // Dispatch the error message to be handled by the reducer
    dispatch(getError({ message: errorMessage, statusCode: errorCode }))
  }
}

// export const fetchUserCouponData = (token) => async (dispatch) => {
//   dispatch(authRequest());

//   try {
//     const result = await axios.get(
//       `${process.env.REACT_APP_API_BASE_URL}users/checkCouponForUser`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     if (result.data && result.status === 200) {
//       dispatch(doneSuccess(result.data));
//     }
//   } catch (error) {
//     dispatch(getError(error));
//   }
// };
