import Grid from '@mui/material/Grid'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Card from '@mui/material/Card'
import { useEffect, useState } from 'react'
import MyDocument from 'components/PDF'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useParams, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import DialogContentText from '@mui/material/DialogContentText'
import MDSelect from 'components/MDSelect'
import TextField from '@mui/material/TextField'
const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  const formattedDate = date.toLocaleDateString('en-GB', options)
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
  return `${formattedDate} ${formattedTime}`
}

function UserView() {
  const { id } = useParams()
  const [userData, setUserData] = useState(null)
  const token = localStorage.getItem('userToken')
  const [open, setOpen] = useState(false)
  const [paymentOpen, setPaymentOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const [description, setDescription] = useState('')
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(-1)
  }

  const handleSelectChange = (value) => {
    setSelectedStatus(value)

    if (value === 'Under_review') {
      setNotificationMessage('We are reviewing your documents.')
    } else if (value === 'Processing') {
      setNotificationMessage('We have received your documents and are processing them.')
    } else if (value === 'Block') {
      setNotificationMessage('Your account has been blocked by the administrator.')
    } else if (value === 'Resubmission') {
      setNotificationMessage('You must submit the document again.')
    } else if (value === 'Rejected') {
      setNotificationMessage('Unfortunately, your document has not met the required standards and has been rejected.')
    } else if (value === 'Completed') {
      setNotificationMessage('We’re excited to let you know that your new NFT has been successfully created!')
    }
  }

  const handleSelectPaymentChange = (value) => {
    setSelectedPaymentStatus(value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handlePaymentClickOpen = () => {
    setPaymentOpen(true)
  }

  const handlePaymentClose = () => {
    setPaymentOpen(false)
  }

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/getUserDetailByID/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      const results = await response.json()

      setUserData(results.data.user)
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Upload failed. Please try again.'
      console.log(errorMessage)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userData !== null) {
      setUserData(userData || '')
    }
  }, [userData])

  const onClickSubmit = async (event) => {
    event.preventDefault()
    const bdy = { status: selectedStatus, description: description, notificationMessage: notificationMessage }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/updateUserStatus/${id}`, {
        method: 'POST',
        body: JSON.stringify(bdy),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        toast.success('Status updated Successfully')
        fetchUserData()
        setSelectedStatus('')
        setDescription('')

        handleClose()
      } else {
        const errorData = await response.json()
        toast.error(errorData.message || 'Unknown error occurred')
      }
    } catch (error) {
      const errorMessage = error.message || 'Upload failed. Please try again.'

      toast.error(errorMessage)
    }
  }

  const onClickPaymentSubmit = async (event) => {
    event.preventDefault()

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/updateUserPaymentStatus/${id}`, {
        method: 'POST',
        body: JSON.stringify({ status: selectedPaymentStatus }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        toast.success('Payment status has been updated Successfully')
        fetchUserData()
        setSelectedPaymentStatus('')
        handlePaymentClose()
      } else {
        const errorData = await response.json()
        toast.error(errorData.message || 'Unknown error occurred')
      }
    } catch (error) {
      const errorMessage = error.message || 'Upload failed. Please try again.'

      toast.error(errorMessage)
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <Toaster />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} lg={8}>
              {' '}
              <MDButton variant="gradient" color="info" onClick={handleBackClick}>
                <Icon sx={{ fontWeight: 'bold' }}>arrow_back</Icon>
                &nbsp;Back
              </MDButton>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                  <Card
                    sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
                      background: gradients['dark'] ? linearGradient(gradients['dark'].main, gradients['dark'].state) : linearGradient(gradients.dark.main, gradients.dark.state),
                      boxShadow: xl,
                      position: 'relative',
                    })}
                  >
                    <MDBox
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                      opacity={0.2}
                      loading="lazy"
                      // sx={{
                      //   backgroundImage: `url(${pattern})`,
                      //   backgroundSize: 'cover',
                      // }}
                    />
                    <MDBox position="relative" zIndex={2} p={2}>
                      <MDBox borderRadius="lg" display="flex" justifyContent="space-between" alignItems="center">
                        {' '}
                        <MDBox color="white" p={1} lineHeight={0} display="inline-block">
                          <Icon fontSize="default">person</Icon>
                        </MDBox>
                        {/* <MDBox ml="auto" lineHeight={0}>
                          
                          <MDButton onClick={() => handleClickOpen(userData.address)} variant="contained" color="white">
                            <img src={editSvg} alt="edit icon" /> &nbsp;Change Status
                          </MDButton>
                        </MDBox> */}
                      </MDBox>
                      <MDTypography variant="h6" color="white" fontWeight="medium" sx={{ mt: 2, mb: 3, pb: 1 }}>
                        {userData ? userData.address : 'Loading...'}
                      </MDTypography>
                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox display="flex" alignItems="center">
                          <MDBox mr={3} lineHeight={1}>
                            <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                              First Name
                            </MDTypography>
                            <MDTypography variant="h6" color="white" fontWeight="medium" textTransform="capitalize">
                              {userData ? userData.first_name : '-'}
                            </MDTypography>
                          </MDBox>
                          <MDBox mr={3} lineHeight={1}>
                            <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                              Last Name
                            </MDTypography>
                            <MDTypography variant="h6" color="white" fontWeight="medium" textTransform="capitalize">
                              {userData ? userData.last_name : '-'}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                            Passport Number
                          </MDTypography>
                          <MDTypography variant="h6" color="white" fontWeight="medium" textTransform="capitalize">
                            {userData ? userData.passport_number : '-'}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <Card>
                    <MDBox p={2} mx={3} display="flex" justifyContent="center">
                      <MDBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="success"
                        color="white"
                        width="4rem"
                        height="4rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">paid</Icon>
                      </MDBox>
                    </MDBox>
                    <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        Payment Status
                      </MDTypography>

                      {/* <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Transaction summary and payment successrmation.
                    </MDTypography> */}

                      <Divider />
                      {userData && userData.paymentStatus ? (
                        userData.paymentStatus === 'PReceived' ? (
                          <MDTypography variant="h6" fontWeight="medium">
                            Received
                          </MDTypography>
                        ) : userData.paymentStatus === 'PNotReceived' ? (
                          <MDTypography variant="h6" fontWeight="medium">
                            Not Received
                          </MDTypography>
                        ) : (
                          <MDTypography variant="h6" fontWeight="medium">
                            {userData.paymentStatus || '-'}
                          </MDTypography>
                        )
                      ) : (
                        <MDTypography variant="h6" fontWeight="medium">
                          Pending
                        </MDTypography>
                      )}
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <Card>
                    <MDBox p={2} mx={3} display="flex" justifyContent="center">
                      <MDBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="success"
                        color="white"
                        width="4rem"
                        height="4rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">pending_actions</Icon>
                      </MDBox>
                    </MDBox>
                    <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        User Status
                      </MDTypography>

                      {/* <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Overview of current status details.
                    </MDTypography> */}

                      <Divider />

                      {/* <MDTypography variant="h5" fontWeight="medium">
                      Pending
                    </MDTypography> */}

                      {userData && userData.status ? (
                        userData.status === 'Under_review' ? (
                          <MDTypography variant="h6" fontWeight="medium">
                            Under Review
                          </MDTypography>
                        ) : userData.status === 'Processing' ? (
                          <MDTypography variant="h6" fontWeight="medium">
                            Creating NFT
                          </MDTypography>
                        ) : (
                          <MDTypography variant="h6" fontWeight="medium">
                            {userData.status}
                          </MDTypography>
                        )
                      ) : (
                        <MDTypography variant="h6" fontWeight="medium">
                          Pending
                        </MDTypography>
                      )}
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card id="delete-account">
                    <MDBox pt={2} px={2} display="flex" justifyContent="center" alignItems="center">
                      <MDTypography variant="h6" fontWeight="medium">
                        Payment and Status Details
                      </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          {/* <MDTypography variant="h6" fontWeight="medium" textAlign="center" mb={2}>
                            Payment Details
                          </MDTypography> */}
                          <MDBox display="flex" justifyContent="space-between" alignItems="center">
                            <MDTypography variant="h6" fontWeight="medium" textAlign="center" mb={1}>
                              Payment Details
                            </MDTypography>
                            <MDButton variant="gradient" color="warning" onClick={() => handlePaymentClickOpen()}>
                              <Icon sx={{ fontWeight: 'bold' }}>edit</Icon>
                              &nbsp;Change Payment Status
                            </MDButton>
                          </MDBox>{' '}
                          {userData && userData.paymentStatus ? (
                            <MDBox
                              position="relative"
                              zIndex={2}
                              p={2}
                              mt={2}
                              borderRadius="lg"
                              sx={{
                                border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <MDTypography variant="h6" color="dark" fontWeight="medium">
                                Transaction Hash: <span style={{ fontSize: 14, color: 'gray' }}>{userData.paymentHash || '-'}</span>
                              </MDTypography>
                              <MDTypography variant="h6" color="dark" fontWeight="medium">
                                Payment Status:{' '}
                                <span style={{ fontSize: 14, color: 'gray' }}>
                                  {userData.paymentStatus === 'PReceived'
                                    ? 'Payment Received'
                                    : userData.paymentStatus === 'PNotReceived'
                                    ? 'Payment Not Received'
                                    : userData.paymentStatus || '-'}
                                </span>
                              </MDTypography>
                              <MDTypography variant="h6" color="dark" fontWeight="medium">
                                Coupon Available: <span style={{ fontSize: 14, color: 'gray' }}>{userData.isCoupon === true ? 'Available' : 'Not Available'}</span>
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDBox
                              position="relative"
                              zIndex={2}
                              p={2}
                              mt={2}
                              textAlign="center"
                              borderRadius="lg"
                              sx={{
                                border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <MDTypography variant="h4" color="dark" fontWeight="medium" mb={2} mt={1}>
                                Payment Process Incomplete
                              </MDTypography>
                              <MDBox ml="auto" lineHeight={0} color="dark">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Payment is pending. Proceed to complete it in the payment section.
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          {/* <MDTypography variant="h6" fontWeight="medium" textAlign="center" mb={2}>
                            Status Details
                          </MDTypography> */}
                          <MDBox display="flex" justifyContent="space-between" alignItems="center">
                            <MDTypography variant="h6" fontWeight="medium" textAlign="center" mb={1}>
                              Status Details
                            </MDTypography>
                            <MDButton variant="gradient" color="warning" onClick={() => handleClickOpen()}>
                              <Icon sx={{ fontWeight: 'bold' }}>edit</Icon>
                              &nbsp;Change Status
                            </MDButton>
                          </MDBox>

                          <MDBox
                            position="relative"
                            zIndex={2}
                            p={2}
                            textAlign="center"
                            borderRadius="lg"
                            mt={2}
                            sx={{
                              border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                            }}
                          >
                            <MDTypography variant="h4" color="dark" fontWeight="medium" mb={2} mt={1}>
                              {userData?.status
                                ? userData.status === 'Under_review'
                                  ? 'Under Review'
                                  : userData.status === 'Processing'
                                  ? 'Approved! Creating NFT Soon 😊'
                                  : userData.status
                                : 'Document Submission Incomplete'}
                            </MDTypography>
                            <MDBox ml="auto" lineHeight={0} color="dark">
                              <MDTypography variant="caption" color="text" fontWeight="regular">
                                {userData?.status
                                  ? 'Your profile is currently under process. We will get back to you later.'
                                  : 'Document submission is pending. Please wait a moment.'}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card sx={{ height: '100%' }}>
                <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" fontWeight="medium">
                    Face Recognition
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {userData ? formatDate(userData.updatedAt) : '-'}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox p={2}>
                  {userData && userData.faceDocument && userData.faceDocument ? (
                    <Grid item xs={12}>
                      <MDBox
                        borderRadius="lg"
                        p={3}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox>
                          <img
                            src={userData.faceCaptureUrls}
                            alt="User"
                            style={{
                              maxWidth: '100%',
                              borderRadius: '8px',
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <MDBox
                        borderRadius="lg"
                        p={3}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox textAlign="center" mb={2}>
                          <MDTypography variant="h5" fontWeight="medium">
                            Face Recognition
                          </MDTypography>
                          <MDTypography variant="body2">No live face capture has been posted as of yet.</MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card id="delete-account">
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Your Uploaded Passport Document
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {userData ? formatDate(userData.updatedAt) : '-'}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox component="li" display="flex" justifyContent="space-between" alignItems="flex-start" bgColor="grey-100" borderRadius="lg" p={3} mb={1} mt={2}>
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: 'flex-start', sm: 'center' }} flexDirection={{ xs: 'column', sm: 'row' }} mb={2}>
                          {userData && userData.documents && userData.documents ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={1}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <MyDocument pdfFile={userData.documentUrls} />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography variant="h5" fontWeight="medium">
                                    Passport
                                  </MDTypography>
                                  <MDTypography variant="body2">No document has been uploaded yet.</MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Your Uploaded Photo
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {userData ? formatDate(userData.updatedAt) : '-'}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox component="li" display="flex" justifyContent="space-between" alignItems="flex-start" bgColor="grey-100" borderRadius="lg" p={3} mb={1} mt={2}>
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: 'flex-start', sm: 'center' }} flexDirection={{ xs: 'column', sm: 'row' }} mb={2}>
                          {userData && userData.photoDocument && userData.photoDocument ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <img
                                    src={userData.photoUrls}
                                    alt="User"
                                    style={{
                                      maxWidth: '100%',
                                      borderRadius: '8px',
                                    }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography variant="h5" fontWeight="medium">
                                    Photo
                                  </MDTypography>
                                  <MDTypography variant="body2">No photo has been uploaded yet.</MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: onClickSubmit,
        }}
      >
        <DialogTitle>Update User Status</DialogTitle>
        <DialogContent>
          <DialogContentText mb={4}>Determine which status to change for the user.</DialogContentText>
          <MDSelect value={selectedStatus} onChange={handleSelectChange} />
          <MDBox mb={2} mt={4}>
            <TextField id="description" name="description" label="Additional Description" type="text" fullWidth margin="dense" onChange={(e) => setDescription(e.target.value)} />{' '}
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton type="submit">Submit</MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={paymentOpen}
        onClose={handlePaymentClose}
        PaperProps={{
          component: 'form',
          onSubmit: onClickPaymentSubmit,
        }}
      >
        <DialogTitle>Update Payment Status</DialogTitle>
        <DialogContent>
          <MDSelect value={selectedPaymentStatus} onChange={handleSelectPaymentChange} pageTitle="PaymentList" />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handlePaymentClose}>Cancel</MDButton>
          <MDButton type="submit">Submit</MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  )
}

export default UserView
