import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDSelect from 'components/MDSelect'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import Icon from '@mui/material/Icon'
import React, { useEffect, useState, useCallback } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import toast, { Toaster } from 'react-hot-toast'
import MDInput from 'components/MDInput'
import editSvg from 'assets/images/edit.svg'
import InputAdornment from '@mui/material/InputAdornment'
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
function Completed() {
  const token = localStorage.getItem('userToken')

  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [user_id, setUserId] = useState('')
  const [description, setDescription] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [dateRange, setDateRange] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  let searchValue = ''
  const handleSearchChange = (event) => {
    searchValue = event.target.value
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    fetchData(currentPage, searchValue, dateRange)
  }

  const handleClickOpen = (user) => {
    setUserId(user._id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleSelectChange = (value) => {
    setSelectedStatus(value)

    if (value === 'Under_review') {
      setNotificationMessage('We are reviewing your documents.')
    } else if (value === 'Processing') {
      setNotificationMessage('We have received your documents and are processing them.')
    } else if (value === 'Block') {
      setNotificationMessage('Your account has been blocked by the administrator.')
    } else if (value === 'Resubmission') {
      setNotificationMessage('You must submit the document again.')
    } else if (value === 'Rejected') {
      setNotificationMessage('Unfortunately, your document has not met the required standards and has been rejected.')
    } else if (value === 'Completed') {
      setNotificationMessage('We’re excited to let you know that your new NFT has been successfully created!')
    }
  }

  const [data, setData] = useState({
    columns: [
      {
        Header: 'Sr.No',
        accessor: 'srno',
        align: 'left',
      },
      {
        Header: 'Wallet Address',
        accessor: 'address',
        align: 'left',
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
        align: 'left',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
        align: 'left',
      },
      {
        Header: 'Passport Number',
        accessor: 'passport_number',
        align: 'left',
      },
      {
        Header: 'created At',
        accessor: 'createdAt',
        align: 'left',
      },
      {
        Header: 'updated At',
        accessor: 'updatedAt',
        align: 'left',
      },

      { Header: 'status', accessor: 'status', align: 'center' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],
    rows: [],
  })

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  const fetchData = useCallback(
    async (currentPage, searchValue, range = null) => {
      setLoading(true)
      try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}users/getUserList/Completed/${currentPage}`
        const dateQuery = range ? `?startDate=${range[0]?.toISOString()}&endDate=${range[1]?.toISOString()}` : ''
        const searchQuery = searchValue ? `${dateQuery ? '&' : '?'}search=${searchValue}` : ''
        const url = `${baseUrl}${dateQuery}${searchQuery}`

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (!response.ok) {
          toast.error('The user information is not found.')
        }
        const jsonData = await response.json()
        setLoading(false)
        const rows = jsonData.data.user.users.map((users, index) => ({
          srno: (currentPage - 1) * 10 + index + 1,
          address: users.address ? users.address : '-',
          first_name: users.first_name ? users.first_name : '-',
          last_name: users.last_name ? users.last_name : '-',
          passport_number: users.passport_number ? users.passport_number : '-',
          createdAt: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {formatDate(users.createdAt)}
              </MDTypography>
            </MDBox>
          ),
          updatedAt: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {formatDate(users.updatedAt)}
              </MDTypography>
            </MDBox>
          ),

          status: (
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="caption" color="text" fontWeight="medium" borderRadius="10rem" sx={{ border: '1px solid #000', padding: 1, marginRight: 1 }}>
                {users.status ? (users.status === 'Under_review' ? 'Under Review' : users.status === 'Processing' ? 'Create NFT' : users.status) : '-'}
              </MDTypography>
              <MDButton onClick={() => handleClickOpen(users)} sx={{ minWidth: 'auto', padding: 0 }}>
                <img src={editSvg} alt="edit icon" />
              </MDButton>
            </MDBox>
          ),
          action: (
            <MDTypography component="a" href={`user/view/${users._id}`} mr={2}>
              <Icon color="success">visibility</Icon>
            </MDTypography>
          ),
        }))
        const totalRows = jsonData.data.user.totalUsersCount

        setData((prevData) => ({
          ...prevData,
          rows: rows,
          totalRows: totalRows,
        }))
      } catch (error) {
        console.log('Error fetching data:', error.message)
      }
    },
    [token],
  )

  const onClickSubmit = async (event) => {
    event.preventDefault()
    const bdy = { status: selectedStatus, description: description, notificationMessage: notificationMessage }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/updateUserStatus/${user_id}`, {
        method: 'POST',
        body: JSON.stringify(bdy),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        toast.success('Status updated Successfully')

        fetchData(currentPage, searchValue, dateRange)
        setSelectedStatus('')
        setDescription('')
        handleClose()
      } else {
        const errorData = await response.json()
        toast.error(errorData.message || 'Unknown error occurred')
      }
    } catch (error) {
      const errorMessage = error.message || 'Upload failed. Please try again.'

      toast.error(errorMessage)
    }
  }
  useEffect(() => {
    fetchData(currentPage, searchValue, dateRange)
  }, [currentPage, fetchData, searchValue, dateRange])

  const [rowsPerPage] = useState(10)
  const totalPagesRowCount = data?.totalRows || 0
  const totalPages = Math.ceil(totalPagesRowCount / rowsPerPage)

  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange)
    setCurrentPage(1)
    fetchData(1, searchValue, newRange)
  }
  const handleCurrentPageChange = (event, value) => {
    setCurrentPage(value)
    fetchData(value, searchValue, dateRange)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Completed User List
                </MDTypography>
              </MDBox>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <MDBox display="flex" alignItems="center" mt={3} px={2}>
                    <DateRangePicker
                      onChange={(range) => {
                        handleDateRangeChange(range)
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox display="flex" alignItems="center" mt={3} px={2}>
                    <form onSubmit={handleSearchSubmit}>
                      <MDInput
                        type="text"
                        placeholder="Search by address"
                        fullWidth
                        onChange={handleSearchChange}
                        InputProps={{
                          sx: {
                            paddingRight: 0,
                            borderRadius: '9.434rem !important',
                          },
                          endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: 0 }}>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                sx={{
                                  minWidth: 'auto',
                                  padding: '6px 12px',
                                  borderRadius: '50%',
                                }}
                              >
                                <Icon fontSize="small">search</Icon>
                              </MDButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox pt={3}>
                {loading ? (
                  <MDTypography align="center">
                    <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                  </MDTypography>
                ) : (
                  <DataTable table={data} isSorted={false} entriesPerPage={false} showTotalEntries={false} canSearch={true} noEndBorder />
                )}

                {/* <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                 
                  <MDButton variant="contained" color="info" size="medium" startIcon={<Icon>chevron_left</Icon>} onClick={handlePrePage} disabled={page === 1}>
                    Previous
                  </MDButton>

                  <MDTypography variant="h6" color="text">
                    Page {page} of {totalPages}
                  </MDTypography>

                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    endIcon={<Icon>chevron_right</Icon>}
                    onClick={handleNextPage}
                    disabled={totalPages === 0 || page === totalPages}
                  >
                    Next
                  </MDButton>
                </MDBox> */}
                <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                  <Stack spacing={2}>
                    <Pagination count={totalPages} color="primary" variant="outlined" shape="rounded" page={currentPage} onChange={handleCurrentPageChange} />
                  </Stack>{' '}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: onClickSubmit, // Form submission handler
        }}
      >
        <DialogTitle>Update User Status</DialogTitle>
        <DialogContent>
          <DialogContentText mb={4}>Determine which status to change for the user.</DialogContentText>
          <MDSelect value={selectedStatus} onChange={handleSelectChange} />
          <MDBox mb={2} mt={4} p={1}>
            <MDInput type="text" label="Addition Description" variant="standard" fullWidth name="description" onChange={(e) => setDescription(e.target.value)} />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  )
}

export default Completed
