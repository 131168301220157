import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import pdffile from '../../sample.pdf'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

function MyDocument(props) {
  //   console.log(props);
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  return (
    <div className="pdf-div">
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <div style={{ height: '500px', overflowY: 'auto' }}>
        <Document file={pdffile} onLoadSuccess={onDocumentLoadSuccess}>
          <Page key={pageNumber} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
      </div>
      <MDBox mt={2} sx={{ '& button': { m: 1 } }}>
        <MDButton onClick={prevPage} disabled={pageNumber === 1} variant="contained" color="info">
          Previous
        </MDButton>
        <MDButton onClick={nextPage} disabled={pageNumber === numPages} variant="contained" color="info">
          Next
        </MDButton>
      </MDBox>
    </div>
  )
}

export default MyDocument
